/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
(function () {

    const container = document.getElementById("site-navigation");
    if (!container) {
        return;
    }

    const button = container.getElementsByTagName("button")[0];
    if ("undefined" === typeof button) {
        return;
    }

    const menu = container.getElementsByTagName("ul")[0];

    // Hide menu toggle button if menu is empty and return early.
    if ("undefined" === typeof menu) {
        button.style.display = "none";
        return;
    }

    if (-1 === menu.className.indexOf("nav-menu")) {
        menu.className += " nav-menu";
    }

    button.onclick = function () {
        if (-1 !== container.className.indexOf("toggled")) {
            container.className = container.className.replace(" toggled", "");
            button.setAttribute("aria-expanded", "false");
        } else {
            container.className += " toggled";
            button.setAttribute("aria-expanded", "true");
        }
    };

    // Close small menu when user clicks outside
    document.addEventListener("click", function (event) {
        const isClickInside = container.contains(event.target);

        if (!isClickInside) {
            container.className = container.className.replace(" toggled", "");
            button.setAttribute("aria-expanded", "false");
        }
    });

    // Get all the link elements within the menu.
    const links = menu.getElementsByTagName("a");
    const len = links.length;

    /**
     * Sets or removes .focus class on an element.
     */
    function toggleFocus() {
        let self = this;

        // Move up through the ancestors of the current link until we hit .nav-menu.
        while (-1 === self.className.indexOf("nav-menu")) {
            // On li elements toggle the class .focus.
            if ("li" === self.tagName.toLowerCase()) {
                if (-1 !== self.className.indexOf("focus")) {
                    self.className = self.className.replace(" focus", "");
                } else {
                    self.className += " focus";
                }
            }

            self = self.parentElement;
        }
    }

    // Each time a menu link is focused or blurred, toggle focus.
    for (let i = 0; i < len; i++) {
        links[i].addEventListener("focus", toggleFocus, true);
        links[i].addEventListener("blur", toggleFocus, true);
    }

    /**
     * Toggles `focus` class to allow submenu access on tablets.
     */
    (function () {
        const parentLink = container.querySelectorAll(".menu-item-has-children > a, .page_item_has_children > a");

        if ("ontouchstart" in window) {
            const touchStartFn = function (e) {
                const menuItem = this.parentNode;

                if (!menuItem.classList.contains("focus")) {
                    e.preventDefault();
                    for (let i = 0; i < menuItem.parentNode.children.length; ++i) {
                        if (menuItem === menuItem.parentNode.children[i]) {
                            continue;
                        }
                        menuItem.parentNode.children[i].classList.remove("focus");
                    }
                    menuItem.classList.add("focus");
                } else {
                    menuItem.classList.remove("focus");
                }
            };

            for (let i = 0; i < parentLink.length; ++i) {
                parentLink[i].addEventListener("touchstart", touchStartFn, false);
            }
        }
    }(container));
}());
