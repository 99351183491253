// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import "owl.carousel";
import "slick-carousel";
import "lightslider";
import "flexslider";

// custom

jQuery(document).ready(function () {
    /* ---------------------- Touch ---------------------- */

    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    } else {
        document.documentElement.className += " touch";
    }

    jQuery(".mobile-trigger").on("click", function () {
        jQuery("body").toggleClass("mobile-open");
        jQuery(document).removeClass("child-open");
        jQuery(".sub-menu").hide();
    });

    jQuery("#carousel").owlCarousel({
        autoplay: true,
        lazyLoad: true,
        loop: true,
        responsiveClass: true,
        autoHeight: true,
        autoplayTimeout: 7000,
        smartSpeed: 800,
        nav: false,
        responsive: {
            0: {
                items: 1,
                nav: true
            },

            767: {
                items: 1,
                nav: true
            },

            1024: {
                items: 1
            },

            1366: {
                items: 1,
                dots: true
            }
        }
    });

    jQuery("#btnFilter").on("click", function () {

        if (jQuery(".cd-filter").is(":visible")) {
            jQuery(".cd-filter").hide();
        }
        else {
            jQuery(".cd-filter").show();
        }

    });

    jQuery("#property_slider").lightSlider({
        gallery: true,
        item: 1,
        loop: true,
        slideMargin: 0,
        thumbItem: 5
    });

    // Can also be used with $(document).ready()
    (jQuery(".flexslider").flexslider({
        animation: "slide",
        controlNav: "thumbnails"
    }))();

    jQuery(".menu-item-has-children").on("click", function () {
        if (jQuery(".menu-item-has-children ul").is(":visible")) {
            jQuery(".menu-item-has-children ul").hide();
        }
        else {
            jQuery(".menu-item-has-children ul").show();
        }
    });

});


jQuery(document).ready(function () {
    jQuery("#sync1").owlCarousel({
        thumbImage: true,
        startPosition: 1,
        items: 1,
        loop: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: false,
        nav: false,
        dots: false
    });
    jQuery(".sync1thumblist li").on("click", function () {
        const iu = jQuery(this).attr("data-val");
        jQuery("#sync1").trigger("to.owl.carousel", [iu - 1, 1]);
    });
    jQuery(".cd-filter-block input").change(function () {
        jQuery.ajax({
            method: "POST",
            url: l10n["tjc_ajax_url"],
            data: jQuery("#search_form").serialize()
        }).done(function (result) {
            jQuery(".all-properties").html(result);
            jQuery(".properties-all .btn-properties").hide();

        });
    });
    jQuery(".btn-properties").on("click", function () {
        jQuery.ajax({
            method: "POST",
            url: l10n["tjc_ajax_url"],
            data: { "action": "loadmorefull" }
        }).done(function (result) {
            jQuery(".all-properties").html(result);
            jQuery(".properties-all .btn-properties").hide();
        });
    });
});
